import { Coupon, SignupFragment as Signup, SignupProduct } from 'generated/graphql';
import { JuniAnalytics } from '@junilearning/juni-analytics-frontend';
import { COURSE_FORMAT } from 'constants/signup_sessions';
import { gtmDataLayerPush, GtmAnalyticsEvent } from 'gtm';
import { STEP_NAMES, STEP_PATHS, StepName } from './constants';
import { getCurrentStepIndex } from './utils';

const FUNNEL = {
  NAME: 'dcf',
  VERSION: 2,
};
const IS_MULTI_SUBJECT_SIGNUP = false;
export type FlowEvent = 'SUBMIT_STEP' | 'BACK';

const commonTracking = (
  signup: Signup,
  currentStep: StepName,
  flowEvent: FlowEvent,
) => {
  const stepPath = STEP_PATHS[currentStep].replaceAll('-', '_');

  const properties = {
    funnelName: FUNNEL.NAME,
    funnelVersion: FUNNEL.VERSION,
    stepName: stepPath,
    ordinal: getCurrentStepIndex(signup, currentStep) + 1,
    routerEventType: flowEvent,
    isMultiSubjectSignup: IS_MULTI_SUBJECT_SIGNUP,
    courseNames: undefined,
    courseFormat: COURSE_FORMAT.private,
  };

  JuniAnalytics.track(stepPath, properties);
};

// Every step event fires a common tracking event
// Some steps then have additional step-specific tracking events
export const handleTracking = (
  signup: Signup,
  currentStep: StepName,
  flowEvent: FlowEvent,
  extra: {
    coupon?: Coupon;
  } = {},
) => {
  commonTracking(signup, currentStep, flowEvent);

  if (flowEvent === 'SUBMIT_STEP') {
    STEP_ANALYTICS[currentStep].handlePostMutationTracking(signup, extra);
  }
};

interface StepAnalytics {
  handlePostMutationTracking: (
    signup: Signup,
    extra?: {
      coupon?: Coupon;
    },
  ) => void;
}

const STEP_ANALYTICS: Record<StepName, StepAnalytics> = {
  [STEP_NAMES.parentInfo]: {
    handlePostMutationTracking: signup => {
      // TODO: should this be pre-mutation?
      JuniAnalytics.track('dcf_lead_submit', {
        signupSessionId: signup._id, // field name should be signupId?
        funnelName: FUNNEL.NAME,
        funnelVersion: FUNNEL.VERSION,
        isMultiSubjectSignup: IS_MULTI_SUBJECT_SIGNUP,
        selectedCourses: undefined,
        courseFormat: COURSE_FORMAT.private,
      });

      window.qp?.('track', 'GenerateLead');
    },
  },
  [STEP_NAMES.studentInfo]: {
    handlePostMutationTracking: () => {
      // noop
    },
  },
  [STEP_NAMES.courseSelection]: {
    handlePostMutationTracking: () => {
      // noop
    },
  },
  [STEP_NAMES.scheduling]: {
    handlePostMutationTracking: () => {
      // noop
    },
  },
  [STEP_NAMES.payment]: {
    handlePostMutationTracking: () => {
      // noop
    },
  },
  [STEP_NAMES.createAccount]: {
    handlePostMutationTracking: (signup, extra = {}) => {
      const {
        couponId: discountCodes,
        customerId: stripeCustomerId,
      } = signup.payment;
      const { userId } = signup;

      // BEGIN: Track 'sign_up' event to Google Tag Manager (GTM)
      try {
        const isTutoringSignup =
          signup.students?.[0]?.enrollments?.[0]?.productName ===
          SignupProduct.PrivateTutoring;
        if (isTutoringSignup) {
          gtmDataLayerPush({ ecommerce: null });
          gtmDataLayerPush({
            event: GtmAnalyticsEvent.SignUp,
            email: signup.parent?.email,
            origin: 'signup2023 (tutoring sign up flow)',
            signupSessionId: signup._id,
            ecommerce: {
              currency: 'USD',
              coupon: extra.coupon ? extra.coupon.id : undefined,
              value: 299,
              items: [
                {
                  item_id: 'prod_core_1',
                  item_name: '1x / week Private 1:1 Tutoring Subscription',
                  item_variant: `private_tutoring_${signup.students?.[0]?.enrollments?.[0]?.tutoringCourse}`,
                  price: 299,
                  quantity: 1,
                },
              ],
            },
            userdata: {
              email: signup.parent?.email,
              first_name: signup.parent?.firstName,
              stripe_id: signup.payment?.customerId,
            },
          });
        }
      } catch (err) {
        console.error('Failed to track sign_up event to GTM', err);
      }
      // END: Track 'sign_up' event to GTM

      JuniAnalytics.track('SubmitCheckoutForm', {
        signupSessionId: signup._id, // field name should be signupId?
        funnelName: FUNNEL.NAME,
        funnelVersion: FUNNEL.VERSION,
        mongoParentId: signup.parent?.parentId,
        stripeCustomerId,
        bundleNames: undefined,
        closeLeadId: undefined, // TODO
        isMultiSubjectSignup: IS_MULTI_SUBJECT_SIGNUP,
        courseFormat: COURSE_FORMAT.private,
        selectedCourses: undefined,
        discountCodes, // could be invalid coupon
        value: undefined,
      });

      if (userId) {
        JuniAnalytics.identify({ userId });
      }

      window.rdt?.('track', 'SignUp');
      window.qp?.('track', 'CompleteRegistration');
      window.ttq?.track('Subscribe');
      window.lintrk?.('track', { conversion_id: 5692052 });
      try {
        if (window?.fpr) {
          window?.fpr('referral', {
            email: signup?.parent?.email || '',
            uid: stripeCustomerId || '',
          });
        }
      } catch (err) {
        console.log('Failed to track signup with FirstPromoter. Error:', err);
      }
    },
  },
  [STEP_NAMES.confirmation]: {
    handlePostMutationTracking: () => {
      // noop
    },
  },
};
